
import AuthService from "@/auth/AuthService";
import ZFunc from "@/service/ZFunc";
import zCONST from "@/const";
import {Vue} from "vue-class-component";

export default class Reset extends Vue{
  AuthService = new AuthService();
  ZF = new ZFunc();
  mReset: {
    USERID: string;
    PASSWD1: string;
    PASSWD2: string;
  } = {
    USERID: '',
    PASSWD1: '',
    PASSWD2: ''
  }
  mMessage: any[] = [];
  mMessageCnt: number = 0;
  mUSERKY:  number = 0;

  created(): void {
    let wHashValue = this.$route.query.hash;

    if (wHashValue == '' || wHashValue == undefined) {
      this.$router.push({ name: "LOGIN" });
      return;
    }

    let wParam = {
      RESEHS: wHashValue
    };

    //リセットハッシュの有効チェック
    this.AuthService.fncCheckResetHash(wParam).then((result:any) => {
      if (result[zCONST.cKEY_RESULT] == true) {
        this.mUSERKY = result["USERKY"];
      }else{
        this.$router.push({ name: "LOGIN" });
      }
    });
  }

  fncReset() {
    if ( this.fncFldCheck() == false ){
      return;
    }

    let wParam = {
      USERKY: this.mUSERKY,
      USERID: this.mReset.USERID,
      PASSWD1: this.mReset.PASSWD1,
      PASSWD2: this.mReset.PASSWD2
    };

    this.AuthService.fncResetUser(wParam).then((result:any) => {
      if (result[zCONST.cKEY_RESULT] == true) {
        alert("設定が完了しました。");
        this.$router.push({ name: "LOGIN" });
      }else{
        this.mMessage.push({severity: "warn", content: "システムエラーが発生しました。管理者に連絡してください。", id: this.mMessageCnt++});
      }
    });
  }

  fncFldCheck(){
    this.mMessage = [];

    if (String(this.mReset.USERID).trim() == "") {
            this.mMessage.push({severity: "warn", content: "ユーザーIDを入力してください", id: this.mMessageCnt++});
    }
    if (String(this.mReset.PASSWD1).trim() == "") {
                this.mMessage.push({severity: "warn", content: "パスワードを入力してください", id: this.mMessageCnt++});
    }
    else{
      if(String(this.mReset.PASSWD1).trim() == String(this.mReset.USERID).trim()){
        this.mMessage.push({severity: "warn", content: "パスワードにユーザーIDは使用できません", id: this.mMessageCnt++});
      }   
      if(this.fncPassCheck(this.mReset.PASSWD1) == false){
          this.mMessage.push({severity: "warn", content: "パスワードは半角大文字、半角小文字、数字、記号を含む文字列を入力してください", id: this.mMessageCnt++});
      }
      else if(this.mReset.PASSWD1.length < 8){
          this.mMessage.push({severity: "warn", content: "パスワードは8文字以上を入力してください", id: this.mMessageCnt++});
      }
    }
 
    if (String(this.mReset.PASSWD1).trim() != String(this.mReset.PASSWD2).trim()) {
      this.mMessage.push({
                severity: "warn",
                content: "確認用パスワードが一致していません",
                id: this.mMessageCnt++,
      });
    }

    if(this.mMessage.length > 0){
        return false;
    }else{
        return true;
    }
  }

  // パスワードチェック
  fncPassCheck(pPasswd:string){
    // 半角小文字
    const w_atz = /[a-z]/;
    if(w_atz.test(pPasswd) == false){
        return false;
    }
    // 半角大文字
    const w_AtZ = /[A-Z]/;
    if(w_AtZ.test(pPasswd) == false){
        return false;
    }
    // 数字
    const w_0t9 = /[0-9]/;
    if(w_0t9.test(pPasswd) == false){
        return false;
    }
    // 記号
    var reg = new RegExp(/[!#$%&\*\+\-@~=/]/g);
    if(reg.test(pPasswd) == false) {
        return false;
    }
    return true;
  }

    // 半角英数字入力制限
    fncInputHankaku(event: InputEvent) {
        event.preventDefault();
        // 半角英数字のみ入力
        if (!(event.target instanceof HTMLInputElement)) {
            return;
        }
        let wTempValue = event.target.value;
        let wTargetId = event.target.id;
        if (wTempValue) {
          wTempValue = wTempValue.replace(/[^0-9a-zA-Z @!#$%&=~/*\-+_]/g, "");
            event.target.value = wTempValue;

            if (wTargetId == "userid"){
              this.mReset.USERID = wTempValue;
            }
            if (wTargetId == "passwd1"){
              this.mReset.PASSWD1 = wTempValue;
            }
            if (wTargetId == "passwd2"){
              this.mReset.PASSWD2 = wTempValue;
            } 
        }
    }
}
